<template>
    <div>
        <!-- Banner -->
        <base-inner-banner
            :banner-image="pageBanner"
            :is-custom-banner="true"
            :banner="banner"
        ></base-inner-banner>
        <!-- Banner -->

        <div class="content-wrapper">
            <div class="container">
                <!-- Page Head -->
                <base-page-head :page-head="pagehead"></base-page-head>
                <!-- Page Head -->

                <!-- Page Contents -->

                <div class="page-contents userAccount">
                    <div class="sidenav-wrapper">
                        <aside class="left-col">
                            <account-nav></account-nav>
                        </aside>
                        <div class="right-col">
                            <div class="activityFeeds">
                                <h2 class="page__title">
                                    {{ $t('Activity Feeds') }}
                                </h2>
                                <div class="table">
                                    <div class="table__head">
                                        <div class="table__row">
                                            <div
                                                class="table__row--cell"
                                                data-width=""
                                            >
                                                {{ $t('Activity') }}
                                            </div>
                                            <div
                                                class="table__row--cell text-center"
                                                data-width="30"
                                            >
                                                {{ $t('Time') }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="table__body">
                                        <div
                                            v-for="activity in activities"
                                            :key="activity.id"
                                            class="table__row"
                                        >
                                            <div
                                                class="table__row--cell"
                                                data-width=""
                                                :data-label="$t('Activity')"
                                            >
                                                <a :href="activity.url">
                                                    <icon
                                                        :name="
                                                            activity.activity_type
                                                        "
                                                    />
                                                    {{
                                                        activity.activity.indexOf(
                                                            ':',
                                                        ) > -1
                                                            ? `${$t(
                                                                  activity.activity.split(
                                                                      ':',
                                                                  )[0],
                                                              )} ${
                                                                  activity.activity.split(
                                                                      ':',
                                                                  )[1]
                                                              }`
                                                            : activity.activity.indexOf(
                                                                  'Updated',
                                                              ) > -1
                                                            ? `${$t(
                                                                  'Updated Idea',
                                                              )} ${activity.activity.substring(
                                                                  activity.activity.indexOf(
                                                                      'Updated',
                                                                  ) + 12,
                                                              )}`
                                                            : $t(
                                                                  activity.activity,
                                                              )
                                                    }}
                                                </a>
                                            </div>
                                            <div
                                                class="table__row--cell text-center"
                                                data-width="30"
                                                :data-label="$t('Time')"
                                            >
                                                <div class="ltr">
                                                    {{
                                                        activity.created
                                                            | dateTime
                                                            | lowerCase
                                                    }}
                                                    –
                                                    {{
                                                        activity.created
                                                            | dateDayMonthYear
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <base-loader v-if="activitiesLoadding" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imgMedium from '@/assets/images/banners/pitch-events-banner-1200X600.png'
import imgLarge from '@/assets/images/banners/pitch-events-banner-1600X700.png'
import imgBig from '@/assets/images/banners/pitch-events-banner-1900X595.png'
import { ActivitiesHelper } from '@/common/crud-helpers/activities'
import { SettingsHelper } from '@/common/crud-helpers/settings'
import Icon from '@/modules/users/components/activity/Icon'
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

const convertToLowerCase = value => value.toLowerCase()
export default {
    components: {
        Icon,
    },
    data() {
        return {
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
                imgAlt: this.$t('Activity Feeds'),
            },
            banner: '',
            pagehead: {
                title: '',
                subtitle: '',
                shortdes: '',
            },
            activities: [],
            activitiesLoadding: true,
            filters: {
                page_size: 9,
                page: 1,
            },
        }
    },
    filters: {
        lowerCase: convertToLowerCase,
    },
    async created() {
        this.getActivities()
        if (!this.dashboardSettings || !this.dashboardSettings.dashboard_tabs) {
            await SettingsHelper.getDashboardSettings()
        } else if (
            this.dashboardSettings &&
            this.dashboardSettings.dashboard_tabs
        ) {
            this.setTitleAndDescription()
        }
    },
    methods: {
        getActivities() {
            this.activitiesLoadding = true
            ActivitiesHelper.list(this.filters)
        },
        getNextData() {
            window.onscroll = () => {
                let bottomOfWindow =
                    document.documentElement.scrollTop + window.innerHeight >
                    document.documentElement.offsetHeight - 600
                if (bottomOfWindow && !this.activitiesLoadding) {
                    let next = this.getNextPage(this.activitiesList.next)
                    if (next) {
                        this.filters.page = next
                        this.getActivities()
                    }
                }
            }
        },
        setTitleAndDescription() {
            let activeTab = this.dashboardSettings.dashboard_tabs.filter(tab =>
                tab.value.url.includes(this.$route.name),
            )[0]
            this.banner = activeTab.value.banner
            this.pagehead.title = activeTab.value[`title_${this.$i18n.locale}`]
            this.pagehead.shortdes =
                activeTab.value[`description_${this.$i18n.locale}`]
        },
    },
    mounted() {
        this.getNextData()
    },
    computed: {
        ...mapGetters(['activitiesList', 'dashboardSettings']),
    },
    watch: {
        activitiesList() {
            let allActivities = _.concat(
                this.activities,
                this.activitiesList.results,
            )
            this.activities = cloneDeep(allActivities)
            this.activitiesLoadding = false
        },
        dashboardSettings() {
            this.setTitleAndDescription()
        },
    },
}
</script>

<style lang="scss" scoped>
.page-contents {
    padding-top: rem(104px);
}
.sidenav-wrapper {
    margin-top: 0;
    /deep/.right-col {
        padding-bottom: 250px;
        .page__title {
            @media screen and (max-width: 1600px) {
                padding-bottom: rem(33px);
            }
            @media screen and (max-width: 991px) {
                padding-bottom: rem(20px);
                padding-top: 22px !important;
            }
        }
    }
}
/deep/.page__title {
    margin: 0 !important;
}
.activityFeeds {
    margin-top: rem(40px);
    @media screen and (max-width: 1450px) {
        margin-top: rem(27.2px);
    }
    @media screen and (max-width: 1199px) {
        margin-top: rem(64px);
    }
    @media screen and (max-width: 991px) {
        margin-top: 0;
    }
    /deep/ {
        .table {
            $self: &;
            padding-left: rem(30px);
            &__row {
                @media screen and (min-width: 992px) {
                    border: 0;
                }
                padding-bottom: 0;
                &--cell {
                    color: #323232 !important;
                    font-size: rem(18px);
                    font-weight: 600;
                    padding: rem(12px) rem(5px);
                    &:first-child {
                        text-align: left;
                    }
                    a {
                        @include flex(flex-start, flex-start);
                        flex-wrap: nowrap;
                        color: #323232;
                        @media screen and (min-width: 1025px) {
                            &:hover {
                                color: var(--primary);
                                text-decoration: none;
                            }
                        }
                    }
                    span {
                        width: 30px;
                        min-width: 30px;
                        height: 30px;
                        background: #582a8e;
                        border-radius: 100%;
                        @include flex(center, center);
                        color: #fff;
                        margin-right: rem(10px);
                        &.red {
                            background: #e4d700;
                        }
                        svg {
                            width: 15px;
                            height: auto;
                        }
                    }
                    @media screen and (max-width: 450px) {
                        &:before {
                            padding-top: rem(35px);
                            padding-bottom: rem(35px);
                        }
                    }
                }
                @media screen and (min-width: 1025px) {
                    &:hover {
                        background: transparent;
                        box-shadow: none;
                    }
                }
            }
            &__head {
                border-color: #fdffd5;
            }
            .table__head .table__row--cell {
                font-weight: 900;
                color: #4a1885 !important;
            }
            @media screen and (min-width: 1025px) {
                .table__body .table__row:hover .table__row--cell {
                    color: #323232 !important;
                }
            }
        }
    }
}
/deep/ {
    .content-wrapper {
        .page__head {
            min-height: auto;
        }
    }
    @media screen and (max-width: 1199px) {
        .content-wrapper {
            .page__head {
                min-height: 135px;
            }
        }
        .page-banner {
            --h: 643px;
        }
    }
    @media screen and (max-width: 991px) {
        .sidenav-wrapper {
            .right-col {
                .page__title {
                    padding-top: rem(55px);
                }
            }
        }
        // .page-banner {
        //     --h: 570px;
        // }
        .table {
            padding-top: rem(55px);
        }
    }
    @media screen and (max-width: 767px) {
        .page-banner {
            --h: 400px;
        }
        .page-contents {
            padding-top: 0;
        }
        .content-wrapper {
            .page__head {
                min-height: 125px;
            }
        }
    }
}

// ar style

.ar {
    .activityFeeds {
        margin-top: rem(24px);
        @media screen and (max-width: 1600px) {
            margin-top: 1.7rem;
        }
        // @media screen and (max-width:1450px){
        //     margin-top: rem(20.8px);
        // }
        @media screen and (max-width: 1199px) {
            margin-top: rem(64px);
        }
        @media screen and (max-width: 991px) {
            margin-top: 0px;
        }
        /deep/ {
            .table {
                $self: &;
                padding-right: rem(30px);
                padding-left: 0;
                &__row {
                    &--cell {
                        &:first-child {
                            text-align: right;
                        }
                        span {
                            margin-left: rem(10px);
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
    .sidenav-wrapper {
        /deep/.right-col {
            .page__title {
                @media screen and (max-width: 991px) {
                    padding-top: 17px !important;
                }
                @media screen and (max-width: 767px) {
                    padding-top: 18px !important;
                }
            }
        }
    }
}
</style>
