<template>
    <span class="red" v-if="name == 'search'">
        <svg
            fill="currentColor"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512.005 512.005"
            style="enable-background:new 0 0 512.005 512.005;"
            xml:space="preserve"
        >
            <use xlink:href="#activitylogs-search-icon"></use>
        </svg>
    </span>
    <span v-else-if="name == 'follow'">
        <svg
            fill="currentColor"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background:new 0 0 512 512;"
            xml:space="preserve"
        >
            <use xlink:href="#activitylogs-heart-icon"></use>
        </svg>
    </span>
    <span v-else-if="name == 'comment'">
        <svg
            fill="currentColor"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 384 338.09"
        >
            <use xlink:href="#activitylogs-comments-icon"></use>
        </svg>
    </span>
    <span v-else-if="name == 'message'">
        <svg
            fill="currentColor"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background:new 0 0 512 512;"
            xml:space="preserve"
        >
            <use xlink:href="#activitylogs-message-icon"></use>
        </svg>
    </span>
    <span v-else-if="name == 'create_idea'">
        <svg
            fill="currentColor"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100.32"
        >
            <path
                class="cls-1"
                d="M86.4,2.5H32A11.14,11.14,0,0,0,20.9,13.6v7.3H13.6A11.14,11.14,0,0,0,2.5,32V86.5A11.14,11.14,0,0,0,13.6,97.6H68A11.14,11.14,0,0,0,79.1,86.5V79.2h7.3A11.14,11.14,0,0,0,97.5,68.1V13.6A11.14,11.14,0,0,0,86.4,2.5ZM44.9,79.3a4.06,4.06,0,0,1-4.1,4.1,4.1,4.1,0,0,1-4.1-4.1v-16h-16a4.1,4.1,0,0,1-4.1-4.1,4.06,4.06,0,0,1,4.1-4.1h16v-16a4.1,4.1,0,1,1,8.2,0v16h16a4.1,4.1,0,1,1,0,8.2h-16ZM89.3,68a2.9,2.9,0,0,1-2.9,2.9H79.1V32A11.14,11.14,0,0,0,68,20.9H29V13.6a2.9,2.9,0,0,1,2.9-2.9H86.4a2.9,2.9,0,0,1,2.9,2.9Z"
            />
        </svg>
    </span>
    <span v-else-if="name == 'like'">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100.32"
            fill="#fff"
        >
            <path
                class="cls-1"
                d="M12.26,41.3H5.33a3,3,0,0,0-3,3V92.14a3,3,0,0,0,3,3h6.93a3,3,0,0,0,3-3V44.29A3,3,0,0,0,12.26,41.3Z"
            />
            <path
                class="cls-1"
                d="M98.78,47c0-4.87-3.34-9.81-9.73-9.81h-29c4.14-7.4,5.36-17.81,2.48-25.43C60.44,6.12,56.4,2.85,51.17,2.52h-.08a6.27,6.27,0,0,0-6.63,5.74c-.74,7.55-4.06,20.92-8.8,25.67-4,4-7.42,5.67-13.09,8.44l-2.66,1.31c0,.21,0,.41,0,.62V91.65l2,.7c9.36,3.22,17.44,6,29.8,6H75.19c6.39,0,9.73-4.94,9.73-9.81a10.59,10.59,0,0,0-.87-4.22,9.26,9.26,0,0,0,5.37-3,10.06,10.06,0,0,0,2.43-6.67A10.51,10.51,0,0,0,91,70.48a9.48,9.48,0,0,0,7.81-9.65,10.12,10.12,0,0,0-2.66-6.93A10.16,10.16,0,0,0,98.78,47Z"
            />
        </svg>
    </span>
    <span v-else-if="name == 'note'">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100.32"
            fill="#fff"
        >
            <polygon
                class="cls-1"
                points="73.97 79.19 93.14 79.19 73.97 98.36 73.97 79.19"
            />
            <path
                class="cls-1"
                d="M88.34,2.5H11.66a4.8,4.8,0,0,0-4.8,4.79V93.57a4.8,4.8,0,0,0,4.8,4.79H64.38V69.6H93.14V7.29A4.8,4.8,0,0,0,88.34,2.5ZM50,64.81H30.83a4.8,4.8,0,0,1,0-9.59H50a4.8,4.8,0,0,1,0,9.59Zm19.17-24H30.83a4.79,4.79,0,1,1,0-9.58H69.17a4.79,4.79,0,0,1,0,9.58Z"
            />
        </svg>
    </span>
</template>

<script>
export default {
    props: {
        name: String,
    },
    components: {},
    data: () => ({}),
    computed: {},
    methods: {},
}
</script>
